.adminBox {
  width: 100%;
  @apply border;
  @apply rounded;
  @apply border-teal-500;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  @apply bg-teal-500;
  @apply text-white;
  @apply h-9;
}

.content {
  text-align: center;
  @apply p-4;
}

.quickAccessBoxContent {
  text-align: center;
  @apply py-4;
}

.variantDark {
  @apply border-teal-700;
}

.variantDark .header {
  @apply bg-teal-700;
}
