@define-mixin desktop {
  @media screen and (min-width: 451px) {
    @mixin-content;
  }
}

@define-mixin mobile {
  @media screen and (max-width: 450px) {
    @mixin-content;
  }
}
